import "./styles.scss"

import React from "react"
import Content from "components/Content"

const History = () => {
  const images = [
    require("assets/images/about-developer/about-developer-image-2.jpg")
      .default,
    require("assets/images/about-developer/about-developer-image-3.jpg")
      .default,
  ]

  return (
    <section className="developer-history">
      <Content type="div" image={images}>
        <h3>Historia</h3>
        <p>
          <strong>
            Firma budowlano – konserwatorska „DES” powstała w 1981 roku przy
            Gminnej Spółdzielni w Michałowicach. Od ponad 40 lat specjalizujemy
            się w pracach budowlano -konserwatorskich w województwach
            Małopolskim i Śląskim.
          </strong>
        </p>
        <p>
          Założyciel firmy Henryk Dowgier dba by o zabytki troszczył się zespół
          wykwalifikowanych pracowników.
        </p>
        <p>
          Za zasługi na rzecz ratowania zabytków polskiej kultury, założyciel
          został wyróżniony przez prezydenta RP Orderem Odrodzenia Polski.
        </p>
      </Content>
    </section>
  )
}

export default History
