import "./styles.scss"

import React from "react"

import Content from "components/Content"
import Counter from "components/Counter"

const About = () => {
  const data = [
    {
      number: 452,
      description: "zrealizowanych projektów",
    },
    {
      number: 232,
      description: "zmodernizowanych budynków",
    },
    {
      number: 42,
      description: "lat doświadczenia w branży",
    },
    {
      number: 187,
      description: "zatrudnionych specjalistów",
    },
  ]

  return (
    <section className="about-developer">
      <Content
        type="div"
        image={
          require("assets/images/about-developer/about-developer-image-1.jpg")
            .default
        }
        isReverse
      >
        <h2>
          Jesteśmy DES. Patrzymy w przyszłość, nie zapominając o przeszłości
        </h2>
        <p>
          <strong>
            Od 1981 roku specjalizujemy się w pracach
            budowlano-konserwatorskich. Za naszym doświadczeniem stoi ponad 360
            zrealizowanych z sukcesem projektów.
          </strong>
        </p>
        <p>
          Zakres naszej działalności obejmuje kompleksowe remonty i adaptacje
          obiektów zabytkowych w pełnym zakresie: od prac konstrukcyjno -
          budowlanych poprzez prace budowlane zewnętrzne, prace konserwatorskie,
          po prace związane z adaptacją i wykończeniem wnętrz. Do Naszych
          specjalności należy zaliczyć remonty elewacji tynkowych, remonty
          elewacji ceglanych remonty dachów oraz wykonanie izolacji pionowych i
          poziomych, konserwację budownictwa drewnianego, renowację.
        </p>
        <p>
          Różnorodne umiejętności i specjalizacje, które posiadają zatrudnione
          osoby sprawiają, że gwarantujemy najwyższy poziom naszych usług.
        </p>
        <div className="about-developer__brand">
          <img
            src={require("assets/images/about-developer/des-logo.jpg").default}
            alt="DES"
          />
          <p>
            <small>
              <a href="https://des.krakow.pl/" target="_blank" rel="noreferrer">
                des.krakow.pl
              </a>
            </small>
          </p>
        </div>
      </Content>
      <div className="container-fluid">
        <div className="row about-developer__row--tiles">
          {data?.map((item, index) => (
            <div key={index} className="col-lg-3 col-sm-6">
              <Counter {...item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default About
