import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { About, History } from "page_components/about-developer"

const AboutDeveloper = () => {
  const title = "O deweloperze"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <About />
      <History />
    </Layout>
  )
}

export default AboutDeveloper
