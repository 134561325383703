import "./styles.scss"

import React from "react"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

const Counter = ({ number, description }) => {
  return (
    <div className="counter">
      <div className="counter__number">
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <>{isVisible ? <CountUp end={number} /> : <span>0</span>}</>
          )}
        </VisibilitySensor>
      </div>
      <p className="counter__description">{description}</p>
    </div>
  )
}

export default Counter
